import { css } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const section = css`
    & > * {
        max-width: 30rem;
        margin: 0 auto 2rem;
    }

    @media (min-width: 44rem) {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;

        & > * {
            flex: 1 1 40%;
            margin: 0 0 4rem;
        }

        & > p {
            flex: 1 1 50%;

            &:nth-of-type(1) {
                margin-right: 4rem;
            }

            &:nth-of-type(2) {
                order: 2;
                margin-left: 4rem;
            }
        }
    }
`;

export const text = css`
    font-size: 1rem;
    line-height: 1.8;
    font-weight: 500;

    @media (min-width: 50rem) {
        font-size: 1.1rem;
    }
`;

export const image = css`
    border-radius: 1rem;
    overflow: hidden;
    border: 6px solid #ffb300;
`;
