import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Wrapper, SEO } from '@components';

import * as s from './_styles';

const AboutPage = () => (
    <Wrapper>
        <SEO title="About Us" keywords={[`gatsby`, `application`, `react`]} />
        <h1 css={s.title}>About Us</h1>
        <div css={s.section}>
            <p css={s.text}>
                Welcome home. Here at 410 Smoke Shop, we pride ourselves on being the finest headshop in Maryland.
                We&apos;ve got the perfect collection of glass, vaporizers, accessories, and more to fit exactly what
                you need. We carry a large selection of local, national, high-end, and imported glass to meet the needs
                from affordability to luxury.
            </p>
            <div css={s.image}>
                <StaticImage
                    src="../../../static/uploads/store-exterior.jpg"
                    alt="410 Smoke Shop Pasadena exterior"
                    layout="constrained"
                    width={550}
                    placeholder="blurred"
                />
            </div>
            <p css={s.text}>
                Since our inception in 2015, locally owned and operated, 410 Smoke Shop remains committed to supplying
                the Baltimore and greater Baltimore area with the highest level of service and products. If you have any
                questions, comments, or feedback please reach out to us and one of our knowledgeable and friendly team
                members will assist in any way we can.
            </p>
            <div css={s.image}>
                <StaticImage
                    src="../../../static/uploads/store-interior.jpg"
                    alt="410 Smoke Shop Pasadena interior"
                    layout="constrained"
                    width={550}
                    placeholder="blurred"
                />
            </div>
        </div>
    </Wrapper>
);

export default AboutPage;
